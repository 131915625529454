import { Loader } from "@googlemaps/js-api-loader";

const mapElement = document.querySelector('#map-references');

if (mapElement) {
    function createMap(locations) {
        const loader = new Loader ({
            apiKey: "AIzaSyA6a2Ya0UUmUPxSMiGJS-P9r_RBD164jUQ",
            version: "weekly",
            libraries: ["marker"]
        });

        let markers = [];
    
        const mapOptions = {
            center: {lat: 50.97377306791086, lng: 2.9431934028415068},
            zoom: 10,
            mapId: "7e1c4c732de8a407",
            mapTypeControl: false,
            streetViewControl: false,
        };
    
        loader 
            .importLibrary('maps', 'marker')
            .then(() => {
                return loader.importLibrary('marker')
            })
            .then (() => {
                if (!google.maps.marker || !google.maps.marker.AdvancedMarkerElement) {
                    console.error("AdvancedMarkerElement is not available.");
                    return;
                }

                const { Map, LatLngBounds } = google.maps;
                const { AdvancedMarkerElement } = google.maps.marker;

                const map = new Map(document.getElementById("map-references"), mapOptions);
                const bounds = new LatLngBounds();

                let openInfoWindow = null;

                locations.forEach(location => {
                    const infowindow = new google.maps.InfoWindow({
                        content: `
                            <div class="map-infowindow">
                                <h2>${location.title}</h2>
                                ${location.url ? `<a class="map-infowindow-link" href="${location.url}">Meer weten</a>` : ''}
                            </div>
                        `
                    });

                    const customMarker = document.createElement('div');
                    customMarker.innerHTML = `
                        <div class="map-marker--${ location.type }"></div>
                    `;

                    const marker = new AdvancedMarkerElement({
                        position: {lat: location.lat , lng: location.lng},
                        map: map,
                        content: customMarker,
                        title: location.title || "Location"
                    });

                    markers.location = location;
                    markers.push(marker)

                    bounds.extend({ lat: location.lat, lng: location.lng });

                    marker.addListener('click', () => {
                        if (openInfoWindow) {
                            openInfoWindow.close();
                        }
                        infowindow.open({
                            anchor: marker,
                            map,
                        });

                        infowindow.setPosition({ lat: location.lat, lng: location.lng });
                        
                        openInfoWindow = infowindow
                    });
                });

                map.fitBounds(bounds);
            })
            .catch((e) => {
                console.error("Error loading the Google Maps library:", e);
            })
    };

    async function fetchCoordinates() {
        const query = `
            query ReferenceQuery {
                referencesEntries {
                    ... on reference_Entry {
                    url
                    title
                    f_projectType
                    f_address {
                        latitude
                        longitude
                    }
                    }
                }
            }
        `;
    
        const response = await fetch('/api', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer vEfoyX4J9_WaDtRBrsgxS3G3RXVghTj0`
            },
            body: JSON.stringify({ query })
        });
    
        const result = await response.json();
        return result.data.referencesEntries
            .filter(entry => entry.f_address && entry.f_address[0]?.latitude && entry.f_address[0]?.longitude)
            .map(entry => ({
                lat: parseFloat(entry.f_address[0].latitude),
                lng: parseFloat(entry.f_address[0].longitude),
                title: entry.title,
                type: entry.f_projectType,
                url: entry.url
        }));
    };
    
    fetchCoordinates().then(locations => {
        if (locations.length > 0) {
            createMap(locations);
        } else {
            console.error("No location found");
        }
    });

};

