import { Loader } from "@googlemaps/js-api-loader";

const mapElement = document.querySelector('#map-contact');

if (mapElement) {
    function createMap(lat, lng) {
        const loader = new Loader ({
            apiKey: "AIzaSyA6a2Ya0UUmUPxSMiGJS-P9r_RBD164jUQ",
            version: "weekly",
            libraries: ["marker"]
        });
    
        const mapOptions = {
            center: {lat: lat, lng: lng},
            zoom: 12,
            mapId: "7e1c4c732de8a407",
            mapTypeControl: false,
            streetViewControl: false,
        };
    
        loader 
            .importLibrary('maps', 'marker')
            .then(() => {
                return loader.importLibrary('marker')
            })
            .then (() => {
                if (!google.maps.marker || !google.maps.marker.AdvancedMarkerElement) {
                    console.error("AdvancedMarkerElement is not available.");
                    return;
                }

                const { Map } = google.maps;
                const { AdvancedMarkerElement } = google.maps.marker;

                const map = new Map(document.getElementById("map-contact"), mapOptions);

                const customMarker = document.createElement('div');
                customMarker.innerHTML = `
                    <div class="map-marker"></div>
                `;
                const marker = new AdvancedMarkerElement({
                    position: {lat: lat , lng: lng},
                    map: map,
                    content: customMarker,
                    title: "our location"
                });
            })
            .catch((e) => {
                console.error("Error loading the Google Maps library:", e);
            })
    };

    async function fetchCoordinates() {
        const query = `
            query CompanyLocation {
                entries(section: "companyInfo") {
                    ... on companyInfo_Entry {
                        f_address {
                            latitude
                            longitude
                        }
                    }
                }
            }
        `;
    
        const response = await fetch('/api', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer lgFUV8WV9XKM0fiZhATiImUB24znFLF2`
            },
            body: JSON.stringify({ query })
        });
    
        const result = await response.json();
        return result.data.entries[0].f_address[0];
    };
    
    fetchCoordinates().then(address => {
        createMap(parseFloat(address.latitude), parseFloat(address.longitude));
    });

};

